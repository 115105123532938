import React, { Suspense, lazy} from 'react';
import { Routes,Route } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const Summary = lazy(() => import('./Summary'))
const Crossing = lazy(()=> import('./Crossing'))
const Testing = lazy(()=> import('./testing'))
const Login = lazy(()=> import('./components/AdminComponents/Login/Login'))
function SwitchContent() {
    return (      
        <Suspense fallback={<Skeleton
            sx={{ bgcolor: 'grey.500' }}
            variant="rectangular"
            height={'100%'}
            />}
        >
        <Routes>
                <Route exact path="/" element={<Summary />} />
                {/*         <Route exact path="/Summary" element={<Summary />} />
                <Route exact path="/testing/*" element={<Testing />}/>
            */}
                <Route exact path="/:Crossing/:TravelMode?/:LaneType?" element={<Crossing />}/>
                <Route path="/admin/*" element={<Login />} />
        </Routes>
        </Suspense>
    );
}

export default SwitchContent;