    import React, { useState, useEffect } from 'react';
    import { Modal, Paper, IconButton, Typography, Box, styled, TextField, Button, useTheme, MenuItem, InputLabel, Select, FormControl } from '@mui/material';
    import { Close as CloseIcon, Height } from '@mui/icons-material';
    import PhoneInput from 'react-phone-number-input';
    import 'react-phone-number-input/style.css'; 
    import ReCAPTCHA from 'react-google-recaptcha'
    import axios from 'axios';
    import { CrossingsInfo } from './feeder';

    const BCQT_API_URL = process.env.REACT_APP_API;

    const StyledModal = styled(Modal)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const ContactContent = styled(Paper)(({ theme }) => ({
        width: '60vw',
        maxHeight: '100vh',
        maxWidth: '800px',
        padding: theme.spacing(2),
        borderRadius: '10px',
        overflowY: 'scroll',
        boxShadow: 'none'
    }));

    const ContactHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
        position: 'relative'
    }));

    const CloseButton = styled(IconButton)(({ theme }) => ({
        position: 'absolute',
        right: '8px'
    }));

    const FormContainer = styled('form')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    }));
    const RecaptchaContainer = styled(Box)({
        display: 'flex',
        justifyContent: 'center',
        alignItems : 'center',
    });
    
    const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: '4px',
        background: 'transparent',     
        '& .PhoneInputInput': {
            background: 'transparent',
            border: 'none', 
            color: theme.palette.text.primary,
            fontSize: '16px', 
            width: '100%',
            padding: '5px'
        },
    }));
    const ContactUsModal = ({ open, onClose }) => {
        const [formData, setFormData] = useState({ name: '', email: '', message: '', messageType: "Report an issue", phone: '', crossing: '' });
        const [recaptcha, setRecaptcha] = useState();
        const [crossings, setCrossings] = useState([]);

        
        const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };
        const handleSubmit = async (e) =>   {
            e.preventDefault();
            if(!recaptcha){
                alert('Please complete the CAPTCHA verification to continue.');
                return;
            };
            const body = {
                "subject": formData.messageType,
                "type": formData.messageType,
                "body": formData.message,
                "sender": {
                        "email": formData.email,
                        "name": formData.name,
                        "phone": formData.phone || '',
                },
                ...(formData.crossing && { "crossing": formData.crossing })
            };
            
            try {
                const res = await axios.post(`${BCQT_API_URL}/se/`, body);
                if (res.status === 200 || res.status === 201) {
                    alert("Message sent successfully!");
                    setFormData({ name: '', email: '', message: '', messageType: 'Report an issue', phone: '', crossing: '', });
                    setRecaptcha(null);
                } else {
                    alert("Something went wrong! Please try again.");
                }
            } catch (error) {
                console.error("Error submitting the form:", error);
                alert("Failed to send the message. Please re-check the entered values");
            }
        };

        const handleRecaptchaSubmit = (value) =>{
            setRecaptcha(value);
        };

        const handlePhoneChange = (value) => {
            setFormData((prev) => ({ ...prev, phone: value || '' }));
        };
        
        return (
            <StyledModal open={open} onClose={onClose}>
                <ContactContent>
                    <ContactHeader>
                        <Typography fontWeight="bold" variant="h4" fontSize="25">Contact Us</Typography>
                        <CloseButton onClick={onClose}>
                            <CloseIcon />
                        </CloseButton>
                    </ContactHeader>
                    <FormContainer onSubmit={handleSubmit}>
                        <TextField label="Your Name" name="name" value={formData.name} onChange={handleChange} required fullWidth />
                        <FormControl fullWidth required>
                            <InputLabel id="type-of-message-label">Message Type</InputLabel>
                            <Select label="Message Type"  name="messageType" labelId="type-of-message-label" id="message-type" value={formData.messageType} onChange={handleChange}>
                                <MenuItem disabled value="">
                                    Select Message Type
                                </MenuItem>
                                <MenuItem value="Report an issue">Report an issue</MenuItem>
                                <MenuItem value="Feedback">Feedback</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel id="crossing-label" shrink>Crossing </InputLabel>
                            <Select label="Crossing"  name="crossing" labelId="crossing-label" id="crossing" value={formData.crossing} onChange={handleChange} displayEmpty>
                                <MenuItem disabled value="">
                                    Select Crossing with an issue
                                </MenuItem>
                                <MenuItem key="None" value="">None</MenuItem>
                                {CrossingsInfo.map((crossing) => {
                                    return (
                                        <MenuItem key={crossing} value={crossing.label}>
                                            {crossing.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <Box sx={{ border:'1px solid #D3D3D3', borderRadius: '4px'}}>
                            <StyledPhoneInput name="phone" defaultCountry="US"  value={formData.phone}  onChange={handlePhoneChange}  placeholder="Enter phone number" international/>
                        </Box>
                        <TextField label="Your Email" name="email" type="email" value={formData.email} onChange={handleChange} required fullWidth />
                        <TextField label="Your Message" name="message" multiline rows={4} value={formData.message} onChange={handleChange} required fullWidth/>
                        <Box textAlign="center" color="red" alignContent="center" justifyContent="center">
                            <Typography fontWeight="light" variant="subtitle2"> *  Indicates a required field </Typography>
                        </Box>
                        <RecaptchaContainer >
                            <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} onChange={handleRecaptchaSubmit}/>
                        </RecaptchaContainer>
                        <Button type="submit" variant="contained" color="primary"> Submit Message</Button>
                    </FormContainer>

                </ContactContent>
            </StyledModal>
        );
    };


    export default ContactUsModal;
